@import "variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $mainFontColor $mainBackgroundColor;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: $mainBackgroundColor
}

*::-webkit-scrollbar-thumb {
  background-color: $mainFontColor;
  border-radius: 20px;
  border: 3px solid $mainBackgroundColor
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $mainBackgroundColor;
  color: $mainFontColor;
}

header {
  background-color: $headerBackgroundColor;
}

nav {
  background-color: $navBarBackgroundColor;
}

nav ul {
  padding: 0;
  list-style-type: none;
}

.nav-current {
  background-color: #004775;
  border: 0.1em solid #007fdb
}

nav a {
  text-decoration: none;
  color: $mainFontColor;
}

a:visited {
  color: $mainFontColor;
}

main {
  overflow-y: auto;
  padding: 1em;
}

input {
  background-color: lighten($mainBackgroundColor, 5%);
  border: none;
  height: 1.75em;
  color: $mainFontColor;
  padding-left: 0.5em;
}

input:focus {
  outline: none;
}

button {
  background-color: lighten($mainBackgroundColor, 5%);
  color: $mainFontColor;
  border: 1px solid $mainFontColor;
  padding: 0.5em;
  border-radius: 6px;
}

button:active {
  background-color: darken($mainBackgroundColor, 1%);
}

button:disabled {
  background-color: lighten($mainBackgroundColor, 20%);
}

p {
  margin-bottom: 1em;
}

.error {
  background-color: rgba(255, 0, 0, 0.5);
  margin-top: 2em;
  margin-bottom: 1em;
  border-color: red;
  border-radius: 1em;
  border-style: solid;
  border-width: 0.1em;
  padding: 0.5em;
  text-align: center;
}

.plusIcon {
  color: green;
}

.checkIcon {
  color: green;
}

.deleteIcon {
  color: red;
}