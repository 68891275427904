.title {
  text-align: center;
}

.container {
  text-align: center;
}

.imFeelingLucky {
  display: inline-block;
  margin-top: 2em;
}

.show {
  display: none;
}

.result {
  margin-top: 2em;
  font-size: 1.5em;
  font-weight: bold;
}