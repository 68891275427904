@import "variables";

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 72px;
  display: flex;
}

h1 {
  font-size: 1.5em;
  width: 7em;
  height: 72px;
  text-align: center;
  margin: auto;
  display: inline-block;
  padding-top: 8px;
}

.hamburger {
  width: 24px;
  height: 2px;
  background-color: $mainFontColor;
  margin: 7px 0;
  border: none;
}

.menu-button {
  border: none;
  padding: 1.5em;
  height: 72px;
  background-color: rgba($color: #000000, $alpha: 0.0);
  display: inline-block;
}

.userBadge {
  display: inline-block;
  height: 72px;
  width: 72px;
  padding-left: 18px;
  padding-top: 18px;
}

.mobile-menu-hidden {
  display: none;
}

.mobile-menu-visible {
  display: block;
  position: fixed;
  top: 72px;
  left: 0px;
  width: 240px;
  height: 480px;
  z-index: 1;
}

//source: https://medium.com/@grmcameron/intials-as-user-icon-with-css-efcccf06a7d8
[data-initial]:before {
  background: #099bdd;
  color: white;
  opacity: 1;
  content: attr(data-initial);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 0.5em;
  width: $userNameBadgeWidth;
  height: $userNameBadgeWidth;
  line-height: $userNameBadgeWidth;
  text-align: center;
}

.login {
  width: 60px;
  margin-right: 12px;
  margin-top: 20px;
  padding: 0;
  height: 32px;
}

nav a {
  background-color: $navBarBackgroundColor;
  color: $mainFontColor;
  display: inline-block;
  padding-left: 1em;
  width: 100%;
  line-height: 2em;
}

main {
  margin-top: 72px;
}

.user-context-menu {
  display: block;
  position: fixed;
  top: 72px;
  right: 0px;
  width: 120px;
  background-color: $navBarBackgroundColor;
}

.user-context-menu-item {
  width: 100%;
  margin: auto;
}

.release-version {
  font-size: 0.5em;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.resultsPreview {
  background-color: red;
  visibility: visible;
}

.hidden {
  display: none;
}

@keyframes thumbsUp {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.testing {
  height: 7em;
  width: 7em;
  animation-name: thumbsUp;
  animation-duration: 1s;
}

.overlay {
  width: 100%; 
  height: 100%; 
  position: absolute; 
  top:0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: green;
}

