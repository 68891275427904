@import 'variables';

@media only screen and (min-width:641px) {
  body {
    overflow-y: hidden;
  }

  .AppContainer {
    display: grid;
    grid-template-rows: 72px 1fr;
    grid-template-columns: 200px 1fr;
    grid-template-areas:
      'header header'
      'nav main'
      'footer footer';
    height: 100vh;
  }

  .menu-button {
    display: none;
  }

  .mobile-menu-visible {
    display: block;
    position: static;
    width: auto;
    height: auto;
  }

  h1 {
    margin-top: 4px;
    font-size: 2.5em;
    display: inline-block;
    width: 13em;
    margin-left: 0.5em;
  }

  nav {
    grid-area: nav;
  }

  .desktop-menu-visible {
    display: block;
  }

  main {
    grid-area: main;
      margin: 0;
  }

  header {
    grid-area: header;
  }

  footer {
    grid-area: footer;
    position: static;
  }

  .release-version {
    left: 130px;
  }

  .search-result-item {
    width: 200px;
    height: 300px;
  }

  .missing-photo-container {
    width: 190px;
    height: 290px;
  }
}