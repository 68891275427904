@import "../../../styles/variables";

.title {
  text-align: center;
}

.container {
  margin-top: 1em;
}

.searchBar {
  width: fit-content;
  margin: 0 auto;
}

.searchTextBox {
  width: 15em;
}

.searchButton {
  border: none;
  height: 1.75em;
  vertical-align: top;
  padding: 0;
  border-radius: 0;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.getStartedMessage {
  margin-top: 2em;
  text-align: center;
}

.watchlist {
  margin-top: 2em;
  margin-bottom: 1em;
}

li {
  display: list-item;
  list-style-position: inside;
  padding-left: 1em;
}

.watchlistItem {
  height: 3em;
  display: inline-flex;
  align-items: center;
  width: 90%;
}

li:nth-child(odd) {
  background-color: lighten($mainBackgroundColor, 5)
}

.complete {
  margin-left: auto;
  margin-right: 1em;
  height: 2em;
  width: 2em;
  padding: 0;
  line-height: 2em;
}

.delete {
  height: 2em;
  line-height: 2em;
  width: 2em;
  padding: 0;
}

.searchText {
  margin-top: 1em;
  margin-bottom: 1em;
}

.searchResults {
  margin-top: 20px;
}

.searchResultItem {
  display: flex;
  height: 3em;
  line-height: 3em;
  align-items: center;
  word-wrap: break-word;
}

.searchResultItem:nth-child(odd) {
  background-color: lighten($mainBackgroundColor, 5);
}

.addMovie {
  margin-left: auto;
  margin-right: 1em;
  height: 2em;
  width: 2em;
  padding: 0;
}

.movieTitle {
  display: inline-block;
  width: 18em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.searchText {
  margin-top: 1em;
  margin-bottom: 1em;
}